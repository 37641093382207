import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    themes: {
      light: {
        primary: '#1F3855',
        secondary: '#3C3B3B',
        accent: '#C0A25B',
        error: '#FF0000',
        warning: '#00FFFF',
        info: '#F0F0F0',
        success: '#0F0F0F'
      }
    }
  }
})
