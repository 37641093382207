import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { store } from './store/store'
import VueI18n from 'vue-i18n'
import Rollbar from 'vue-rollbar'
import axios from 'axios'
import GUID from './js/guid'
import Amplify from 'aws-amplify'
import Snotify from 'vue-snotify'
import 'vue-snotify/styles/material.css'
import serviceworker from './serviceworker'

Amplify.configure(process.env.AWSConfig)

Vue.config.productionTip = false

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'en'
})

const s3Axios = axios.create({
  baseURL: process.env.S3,
  headers: { 'Cache-Control': 'no-cache' }
})

Vue.use(Rollbar, {
  accessToken: '946f3daefbf4498f9558b28f69f27d48',
  payload: {
    environment: process.env.NODE_ENV
  }
})

Vue.config.errorHandler = function (err, vm) {
  if (process.env.NODE_ENV !== 'development') {
    if (Vue.rollbar != null) {
      Vue.rollbar.error(err)
    }
  }

  vm.$nextTick(function () {
    // Open dialog and show.
  })
}

Vue.use(Snotify)

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
  async created () {
    const scope = this

    serviceworker.remove()

    this.$store.commit('session/setIsDirty', false)

    if (scope.$store.getters.getBrowserId == null) {
      scope.$store.commit('session/setBrowserId', GUID.generateUUID())
    }

    window.onbeforeunload = function (e) {
      if (scope.$store.getters['session/isDirty']) {
        return 'You have unsaved changes on this page. Do you want to leave and discard your changes or stay ?'
      }
    }

    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload()
      }
    }

    // Set up the URLs.
    const dictionaryUrl = `${window.location.host}/dictionary.json`
    const dateTimeUrl = `${window.location.host}/dateTime.json`
    const configUrl = `${window.location.host}/config.json`

    try {
      const dictionary = await s3Axios.get(dictionaryUrl)
      i18n.setLocaleMessage('en', dictionary.data.en)

      const dateTime = await s3Axios.get(dateTimeUrl)
      i18n.setDateTimeFormat('en', dateTime.data.en)

      const config = await s3Axios.get(configUrl)
      scope.$store.dispatch('config/setConfig', config.data)
    } catch (err) {
      console.error(err)
    }

    scope.$store.dispatch('hubs/connectIssueHub')
  }
}).$mount('#app')
