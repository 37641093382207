export default {
  remove () {
    if ('serviceWorker' in navigator) {
      // Clear service workers.
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          registration.unregister()
        }
      })
    }
  }
}
