const namespaced = true

const state = {
  config: {}
}

// getters
const getters = {
  getSelectedLookup: (state) => (name) => {
    if (state.config.properties) {
      return state.config.properties[name]
    }

    return []
  },
  config: (state) => {
    return state.config
  },
  getPropertyType: (state) => (obj) => {
    const group = obj.group
    const value = obj.value
    if (state.config.properties[group]) {
      const item = state.config.properties[group].find(l => l.value === value)
      if (state.config.properties[group]) {
        if (item) return item.text
        return ''
      }
    }
    return 'N/A - Property'
  }
}

// actions
const actions = {
  setConfig (context, config) {
    context.commit('setConfig', config)
  }
}

// mutations
const mutations = {
  setConfig (state, data) {
    state.config = data
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
