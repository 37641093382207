import { s3Source } from './../../js/s3-helper'

const namespaced = true

// state
const state = {
  notifications: [],
  drawer: false,
  issueSocket: null
}

// getters
const getters = {
  notifications: (state) => {
    return state.notifications
  },
  drawer: (state) => {
    return state.drawer
  }
}

// actions
const actions = {
  setDrawer (context, open) {
    context.commit('setDrawer', open)
  },
  async newMessage (context, messageNotification) {
    if (messageNotification.filesJSON && messageNotification.filesJSON.length > 0) {
      for (let i = 0; i < messageNotification.files.length; i++) {
        const file = messageNotification.filesJSON[i]
        const url = await s3Source(file)
        messageNotification.filesJSON[i].url = url
      }
    }
    context.commit('addNotification', messageNotification)
  }
}

// mutations
const mutations = {
  removeNotification (state, data) {
    const index = state.notifications.findIndex(i => i.id === data.id)
    if (index !== -1) {
      // using Vue.set
      state.notifications.splice(index, 1)
    }
  },
  setNotification (state, data) {
    state.notifications = data
  },
  addNotification (state, data) {
    state.notifications.push(data)
  },
  setDrawer (state, bool) {
    state.drawer = bool
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
