import dataManager from '../../components/dataManager'
const namespaced = true
const state = {
  user: {},
  token: null,
  isLoggedIn: false,
  isDirty: false,
  rememberEmail: null,
  browserId: null,
  online: true,
  permissions: {
    routes: [],
    keys: [],
    defaultRoute: ''
  },
  search: '',
  userInformation: {}
}

// getters
const getters = {
  getUser: (state) => {
    return state.user
  },
  getToken: (state) => {
    return state.token
  },
  isLoggedIn: (state) => {
    return state.isLoggedIn
  },
  getBrowserId: (state) => {
    return state.browserId
  },
  isDirty: (state) => {
    return state.isDirty
  },
  hasRoute: (state) => (key) => {
    return state.permissions.routes.includes(key)
  },
  hasPermissionKey: (state) => (key) => {
    return key ? state.permissions.keys.includes(key) : true
  },
  getDefaultRoute: (state) => {
    return state.permissions.defaultRoute
  },
  userInformation: (state) => {
    return state.userInformation
  },
  onlineStatus: (state) => {
    return state.online
  },
  getCognitoGroup: (state) => (group) => {
    if (state.user && state.user.idToken && state.user.idToken.payload && state.user.idToken.payload['cognito:groups']) {
      const item = state.user.idToken.payload['cognito:groups'].find(f => f === group)
      if (item) {
        return true
      }
    }
    return false
  },
  getEmail: (state) => {
    if (state.user && state.user.idToken && state.user.idToken.payload && state.user.idToken.payload.email) {
      return state.user.idToken.payload.email
    }
    return 'no email'
  },
  search: (state) => {
    return state.search
  }
}

// actions
const actions = {
  saveUser (context, userInformation) {
    return new Promise((resolve, reject) => {
      dataManager.put('user/' + userInformation.id, userInformation)
        .then(function (data) {
          context.commit('setUserInformation', data)
          resolve(data)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },

  checkStatus (context) {
    dataManager.get('v1/status')
      .then(() => {
        // If we have a response from the server, we are online
        context.commit('setOnline', true)
      })
      .catch((err) => {
        if (err.message && err.message === 'Network Error') {
          context.commit('setOnline', false)
        }
      })
  },

  setSearch (context, search) {
    context.commit('setSearch', search)
  },

  retrieveUserInformation (context) {
    return new Promise((resolve, reject) => {
      dataManager.get('user/').then(function (data) {
        context.commit('setUserInformation', data)
        resolve(data)
      })
        .catch(function (err) {
          reject(err)
        })
    })
  }
}

// mutations
const mutations = {
  setSessionUser (state, data) {
    state.user = data
  },
  setToken (state, token) {
    state.token = token
  },
  setIsLoggedIn (state, bool) {
    state.isLoggedIn = bool
  },
  setIsDirty (state, bool) {
    state.isDirty = bool
  },
  setPermissions (state, data) {
    state.permissions = data
  },
  setBrowserId (state, data) {
    state.browserId = data
  },
  setOnline (state, bool) {
    state.online = bool
  },
  setSearch (state, search) {
    state.search = search
  },
  setUserInformation (state, userInformation) {
    state.userInformation = userInformation
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
}
