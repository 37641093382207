<template>
  <v-app>
    <Header />
    <!-- <Notifications /> -->
    <v-main>
      <router-view :key="$route.path" />
    </v-main>
    <Footer />
    <vue-snotify></vue-snotify>
  </v-app>
</template>

<script>
import Header from './components/header.vue'
import Footer from './components/footer.vue'
// import Notifications from './components/notifications/notifications.vue'

export default {
  name: 'App',

  components: {
    Header,
    // Notifications,
    Footer
  },

  data: () => ({
    //
  })
}
</script>
<style></style>
