import dataManager from '../../components/dataManager'
import moment from 'moment'
import Vue from 'vue'

const namespaced = true

const state = {
  issues: [],
  issue: {
    parentId: null,
    dataJSON: {

    },
    areaId: null,
    type: null,
    filesJSON: []
  },
  selectedIssue: {},
  issueMessages: [],
  showIssue: true,
  showTour: false
}

// getters
const getters = {
  showIssue: (state) => {
    return state.showIssue
  },
  showTour: (state) => {
    return state.showTour
  },
  issues: (state) => {
    const res = state.issues.sort(function (a, b) {
      return b.updatedAt - a.updatedAt
    })
    return res
  },
  selectedIssue: (state) => {
    return state.selectedIssue
  },
  issueMessages: (state) => {
    return state.issueMessages
  }
}

// actions
const actions = {
  newIssue (context, data) {
    const newIssue = JSON.parse(JSON.stringify(context.state.issue))
    if (data.areaId) {
      newIssue.areaId = data.areaId
    }

    if (data.parentId) {
      newIssue.parentId = data.parentId
    }

    if (data.type) {
      newIssue.type = data.type
    }
    context.commit('setSelectedIssue', newIssue)
  },
  retrieveIssue (context, issueId) {
    return new Promise((resolve, reject) => {
      dataManager.get(`issues/${issueId}`).then(function (res) {
        context.commit('setSelectedIssue', res)
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  retrieveIssues (context) {
    return new Promise((resolve, reject) => {
      dataManager.get('issues/').then(function (res) {
        res = res.sort(function (a, b) {
          return b.updatedAt - a.updatedAt
        })
        context.commit('setIssues', res)
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  retrieveIssueMessages (context, id) {
    // const scope = this
    return new Promise((resolve, reject) => {
      dataManager.get('issues/' + id + '/messages').then(function (res) {
        res = res.sort(function (a, b) {
          return moment(a.updatedAt) - moment(b.updatedAt)
        })
        context.commit('setIssueMessages', res)
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  createIssue (context, issue) {
    // Clone so we can change what is being sent to remove some fields that
    // need to be there for screen but no need to send e.g. image src dataurl.
    const postIssue = JSON.parse(JSON.stringify(issue))
    postIssue.message = 'Issue Has been Raised'
    postIssue.imageSrc = null

    if (postIssue.filesJSON) {
      if (postIssue.filesJSON && postIssue.filesJSON.length > 0) {
        for (let i = 0; i < postIssue.filesJSON.length; i++) {
          delete postIssue.filesJSON[i].url
          delete postIssue.filesJSON[i].thumb_url
        }
      }
    }
    return new Promise((resolve, reject) => {
      dataManager.post('issues/', postIssue).then(function (res) {
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  updateIssue (context, issue) {
    // const scope = this
    if (issue.filesJSON) {
      if (issue.filesJSON && issue.filesJSON.length > 0) {
        for (let i = 0; i < issue.filesJSON.length; i++) {
          // delete this.issue.filesJSON[i].url
          delete issue.filesJSON[i].thumb_url
        }
      }
    }
    return new Promise((resolve, reject) => {
      dataManager.put('issues/' + issue.id, issue).then(function (res) {
        context.commit('setSelectedIssue', issue)
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  submitIssue (context, issue) {
    return new Promise((resolve, reject) => {
      dataManager.put('issues/' + issue.Id + '/submit').then(function (res) {
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  deleteIssue (context, id) {
    return new Promise((resolve, reject) => {
      dataManager.delete(`issues/${id}`).then(function (res) {
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  createMessage (context, message) {
    return new Promise((resolve, reject) => {
      dataManager.post(`issues/${message.issueId}/messages/`, message).then(function (res) {
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  updateIssueStore (context, issue) {
    context.commit('updateIssue', issue)
  }
}

// mutations
const mutations = {
  setNewIssue (state, data) {
    state.issue = data
  },
  updateIssue (state, data) {
    const index = state.issues.findIndex(i => i.id === data.id)
    if (index !== -1) {
      // using Vue.set
      Vue.set(state.issues, index, data)
    }
  },
  addIssues (state, data) {
    state.issues.unshift(data)
  },
  setShowIssue (state, bool) {
    state.showIssue = bool
  },
  setShowTour (state, bool) {
    state.showTour = bool
  },
  addIssueMessages (state, data) {
    const message = state.issueMessages.find(m => m.id === data.id)
    if (!message) {
      state.issueMessages.push(data)
    }
  },
  setIssues (state, data) {
    state.issues = data
  },
  setIssueMessages (state, data) {
    state.issueMessages = data
  },
  setSelectedIssue (state, data) {
    state.selectedIssue = data
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
