<template>
  <v-app-bar
    app
    fixed
    color="white"
    elevate-on-scroll
    v-if="visible"
  >
    <v-avatar size="55px">
      <img
        src="../../static/img/icons/apple-icon-180.png"
        alt="profile picture"
      >
    </v-avatar>

    <v-spacer></v-spacer>

    <v-btn color="primary" icon @click="showSearch = true" v-if="!showSearch">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <v-scroll-x-reverse-transition>
        <v-text-field
          class="mt-2"
          v-if="showSearch || this.search"
          prepend-icon="search"
          :append-icon="search === '' ? '' : 'close'"
          placeholder="Search..."
          color="primary"
          @click:prepend="showSearch = false"
          @click:append="clearOrClose()"
          v-model="search"
        ></v-text-field>
      </v-scroll-x-reverse-transition>

    <v-btn id="no-background-after-click" color="primary" icon @click="refreshData()">
      <v-icon>mdi-refresh</v-icon>
    </v-btn>

    <!-- <v-badge
      bordered
      :value="showNotificationBadge"
      :content="notificationCount"
      color="red"
      offset-x="22"
      offset-y="22"
    >
      <v-btn color="primary" icon @click="showNotificationDrawer">
        <v-icon>mdi-bell</v-icon>
      </v-btn>
    </v-badge> -->
  </v-app-bar>
</template>

<script>
import { EventBus } from './events/eventBus'

export default {
  name: 'Header',
  data () {
    return {
      showSearch: false
    }
  },
  computed: {
    visible () {
      if (this.$route.meta.headerVisible) {
        return true
      } else {
        return false
      }
    },
    notificationCount () {
      return this.$store.getters['notifications/notifications'].length
    },
    showNotificationBadge () {
      if (this.$store.getters['notifications/notifications'].length > 0) {
        return true
      }
      return false
    },
    search: {
      get: function () {
        return this.$store.getters['session/search']
      },
      // setter
      set: function (newValue) {
        this.$store.dispatch('session/setSearch', newValue)
      }
    }
  },
  methods: {
    showNotificationDrawer: function () {
      this.$store.dispatch('notifications/setDrawer', true)
    },
    refreshData () {
      EventBus.$emit('refreshData', true)
    },
    clearOrClose () {
      this.showSearch = false
      this.search = null
    }
  }
}
</script>
<style scoped>
#no-background-after-click::before {
   background-color: transparent !important;
}
</style>
