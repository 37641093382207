import { Storage } from 'aws-amplify'
export async function s3Source (img) {
  const item = await Storage.get(img.key, {
    level: img.level
  })
  return item
}
export async function s3ThumbSource (img) {
  if (img.thumb_key) {
    const item = await Storage.get(img.thumb_key, {
      level: img.level
    })
    return item
  }
  return ''
}
