import dataManager from '../../components/dataManager'

const namespaced = true

const state = {
  areas: [],
  selectedArea: {},
  selectedPlotParentArea: {},
  selectedList: {},
  showArea: false,
  areaIssues: [],
  areaGroupIssues: []
}

// getters
const getters = {
  showArea: (state) => {
    return state.showArea
  },
  areas: (state) => {
    // let res = state.areas.sort(function (a, b) {
    //   return b.updatedAt - a.updatedAt
    // })
    return state.areas
  },
  selectedArea: (state) => {
    return state.selectedArea
  },
  selectedPlotParentArea: (state) => {
    return state.selectedPlotParentArea
  },
  selectedList: (state) => {
    return state.selectedList
  },
  areaIssues: (state) => {
    return state.areaIssues
  },
  areaGroupIssues: (state) => {
    return state.areaGroupIssues
  }
}

// actions
const actions = {
  retrieveAreas (context) {
    return new Promise((resolve, reject) => {
      dataManager.get('areas').then(function (res) {
        context.commit('setAreas', res)
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  retrieveArea (context, id) {
    return new Promise((resolve, reject) => {
      dataManager.get('areas/' + id).then(function (area) {
        context.commit('setSelectedArea', area)
        resolve(area)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  retrievePlotParentArea (context, id) {
    return new Promise((resolve, reject) => {
      dataManager.get('areas').then(function (res) {
        const area = res.find(a => a.id === id)
        context.commit('setSelectedPlotParentArea', area)
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  retrieveAreaIssues (context, id) {
    return new Promise((resolve, reject) => {
      dataManager.get('areas/' + id + '/issues').then(function (res) {
        context.commit('setAreaIssues', res)
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  retrieveGroupAreaIssues (context, id) {
    return new Promise((resolve, reject) => {
      dataManager.get('areas/' + id + '/issues').then(function (res) {
        context.commit('setAreaGroupIssues', res[0].issues)
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  updatePlot (context, plot) {
    return new Promise((resolve, reject) => {
      dataManager.put('areas/' + plot.id, plot).then(function (res) {
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  generateReport (context, queryData) {
    return new Promise((resolve, reject) => {
      dataManager.get('areas/generate-report/' + queryData.id).then(function (res) {
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  }
}

// mutations
const mutations = {
  setShowArea (state, bool) {
    state.showArea = bool
  },
  setAreas (state, data) {
    state.areas = data
  },
  setAreaIssues (state, data) {
    state.areaIssues = data
  },
  setAreaGroupIssues (state, data) {
    state.areaGroupIssues = data
  },
  setSelectedArea (state, data) {
    state.selectedArea = data
  },
  setSelectedPlotParentArea (state, data) {
    state.selectedPlotParentArea = data
  },
  setSelectedList (state, data) {
    state.selectedList = data
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
