import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import modules from './modules'
Vue.use(Vuex)

export const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['session']
    }),
    createPersistedState({
      key: 'offline',
      paths: ['offline']
    })],
  modules,

  state: {
    maintenanceVisible: false,
    maintenanceMessage: 'Maintenance is about to happen',
    maintenanceExpectedEnd: '',
    maintenanceClosable: true,
    browser: {},
    snackBar: {
      show: false,
      text: ''
    }
  },
  mutations: {
    setMaintenanceVisible (state, data) {
      state.maintenanceVisible = data
    },
    setMaintenanceMessage (state, data) {
      state.maintenanceMessage = data
    },
    setMaintenanceExpectedEnd (state, data) {
      state.maintenanceExpectedEnd = data
    },
    setMaintenanceClosable (state, data) {
      state.maintenanceClosable = data
    },
    setBrowser (state, obj) {
      state.browser = obj
    },
    setSnackBar (state, obj) {
      state.snackBar = obj
    }
  },
  getters: {
    getMaintenanceVisible: (state) => {
      return state.maintenanceVisible
    },
    getMaintenanceMessage: (state) => {
      return state.maintenanceMessage
    },
    getMaintenanceExpectedEnd: (state) => {
      return state.maintenanceExpectedEnd
    },
    getMaintenanceClosable: (state) => {
      return state.maintenanceClosable
    },
    getBrowser: (state) => {
      return state.browser
    },
    getSnackBar: (state) => {
      return state.snackBar
    }
  }
})
