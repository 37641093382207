import axios from 'axios'
import { store } from '../store/store'
import GUID from '../js/guid'
import router from '../router/index.js'
import { Auth } from 'aws-amplify'

export default {
  useOfflineMode: false,
  isPostingData: false,

  /**
   * Main method to GET data from the server
   *
   * @param {*} url
   * @param {*} value
   * @param {*} progressCallback
   * @param {*} offlineCallbackFunction
   */
  get (url, value, progressCallback, offlineCallbackFunction) {
    // Local copy of the store
    const localStoreObj = store
    const localUseOfflineMode = this.useOfflineMode

    return new Promise(function (resolve, reject) {
      // Build the config
      const config = {
        onUploadProgress: progressEvent => {
          if (progressCallback != null) {
            progressCallback(progressEvent)
          }
        }
      }
      // Build the URL
      const fullpath = process.env.APIENDPOINT + url
      // Try to make the request to the server
      axios.get(fullpath, { params: value, config: config })
        .then(function (res) {
          // If all is well return the data from the server
          resolve(res.data)
        })
        .catch(function (err) {
          if (err != null && err.message === 'Network Error' && localUseOfflineMode === true) {
            // If an offline callback is specified use it
            if (offlineCallbackFunction != null) {
              resolve(localStoreObj.dispatch(offlineCallbackFunction, { url: url, value: value, state: localStoreObj.state }))
            } else {
              resolve()
            }
          } else if (err.response) {
            if (err.response.status === 401) {
              reject(new Error(err.response.statusText + ' - You do not have access to view this data'))
            } else {
              reject(err)
            }
          } else {
            reject(err)
          }
        })
    })
  },

  /**
   * Main method to POST data from the server
   *
   * @param {*} url
   * @param {*} value
   * @param {*} progressCallback
   * @param {*} offlineCallbackFunction
   */
  post (url, value, progressCallback, offlineCallbackFunction) {
    // Local copies
    const scope = this
    const localStoreObj = store
    const localUseOfflineMode = this.useOfflineMode

    // If nothing is waiting to be sent this can be initiated immediately
    if (!localUseOfflineMode || localStoreObj.getters['offline/isOfflineQueueEmpty']) {
      // Try to make the request to the server
      return new Promise(function (resolve, reject) {
        // Build a config based on the parameters
        const config = {
          onUploadProgress: progressEvent => {
            if (progressCallback != null) {
              progressCallback(progressEvent)
            }
          }
        }
        // Post
        axios.post(process.env.APIENDPOINT + url, value, config)
          .then(function (res) {
            resolve(res.data)
          })
          .catch(function (err) {
            if (localUseOfflineMode === true && err != null && err.message === 'Network Error' && offlineCallbackFunction != null) {
              // Create the post object
              const postRequestObj = scope.createRequestObject('POST', url, value)
              localStoreObj.dispatch('offline/enqueueOfflineItem', { item: postRequestObj, state: localStoreObj.state.offline })
              resolve(localStoreObj.dispatch(offlineCallbackFunction, { url: url, value: value, state: localStoreObj.state }))
            } else {
              let errorMessage = ''
              const responseObj = err.response
              if (responseObj != null) {
                if (responseObj.data != null && Array.isArray(responseObj.data) && responseObj.data.length > 0) {
                  errorMessage += 'Save Error - ' + responseObj.data[0].description
                } else if (responseObj.data != null) {
                  errorMessage = responseObj.data.message
                }
              } else {
                errorMessage = err.message
              }
              reject(new Error(errorMessage))
            }
          })
      })
    } else {
      // Use the offline function and cache the request
      return new Promise(function (resolve, reject) {
        if (offlineCallbackFunction != null) {
          const postRequestObj = scope.createRequestObject('POST', url, value)
          localStoreObj.dispatch('offline/enqueueOfflineItem', { item: postRequestObj, state: localStoreObj.state.offline })
          resolve(localStoreObj.dispatch(offlineCallbackFunction, { url: url, value: value, state: localStoreObj.state }))
        } else {
          reject(new Error('System is offline and configured to handle offline requests but no handling is setup for this method'))
        }
      })
    }
  },

  /**
   * Main method to put values on the server
   *
   * @param {*} url
   * @param {*} value
   * @param {*} progressCallback
   * @param {*} offlineCallbackFunction
   */
  put (url, value, progressCallback, offlineCallbackFunction) {
    // Local copies
    const scope = this
    const localStoreObj = store
    const localUseOfflineMode = this.useOfflineMode
    // If nothing is waiting to be sent this can be initiated immediately
    if (!localUseOfflineMode || localStoreObj.getters['offline/isOfflineQueueEmpty']) {
      // Try to make the request to the server
      return new Promise(function (resolve, reject) {
        // Build a config based on the parameters
        const config = {
          onUploadProgress: progressEvent => {
            if (progressCallback != null) {
              progressCallback(progressEvent)
            }
          }
        }
        // Try the put request to the server
        axios.put(process.env.APIENDPOINT + url, value, config)
          .then(function (res) {
            resolve(res.data)
          })
          .catch(function (err) {
            if (localUseOfflineMode === true && err != null && err.message === 'Network Error') {
              const putRequestObj = scope.createRequestObject('PUT', url, value)
              localStoreObj.dispatch('offline/enqueueOfflineItem', { item: putRequestObj, state: localStoreObj.state.offline })
              if (offlineCallbackFunction != null) {
                resolve(localStoreObj.dispatch(offlineCallbackFunction, { url: url, value: value, state: localStoreObj.state }))
              } else {
                resolve()
              }
            } else {
              let errorMessage = ''
              const responseObj = err.response
              if (responseObj != null) {
                if (responseObj.data != null && Array.isArray(responseObj.data) && responseObj.data.length > 0) {
                  errorMessage += 'Save Error - ' + responseObj.data[0].description
                } else if (responseObj.data != null) {
                  errorMessage = responseObj.data.message
                }
              } else {
                errorMessage = err.message
              }
              reject(new Error(errorMessage))
            }
          })
      })
    } else {
      // Use the offline function and cache the request
      return new Promise(function (resolve) {
        const putRequestObj = scope.createRequestObject('PUT', url, value)
        localStoreObj.dispatch('offline/enqueueOfflineItem', { item: putRequestObj, state: localStoreObj.state.offline })
        if (offlineCallbackFunction != null) {
          resolve(localStoreObj.dispatch(offlineCallbackFunction, { url: url, value: value, state: localStoreObj.state }))
        } else {
          resolve()
        }
      })
    }
  },

  /**
   * Main method to delete values from the server
   *
   * @param {*} url
   * @param {*} value
   */
  delete (url, value) {
    return new Promise(function (resolve, reject, cb) {
      const config = {
        onUploadProgress: progressEvent => {
          if (cb != null) {
            cb(progressEvent)
          }
        }
      }
      const fullpath = process.env.APIENDPOINT + url
      axios.delete(fullpath, {
        params: value,
        config: config
      })
        .then(function (res) {
          resolve(res.data)
        })
        .catch(function (err) {
          let errorMessage = ''
          const responseObj = err.response
          if (responseObj != null) {
            if (responseObj.data != null && Array.isArray(responseObj.data) && responseObj.data.length > 0) {
              errorMessage += 'Save Error - ' + responseObj.data[0].description
            } else if (responseObj.data != null) {
              errorMessage = responseObj.data.message
            }
          } else {
            errorMessage = err.message
          }
          reject(new Error(errorMessage))
        })
    })
  },
  /**
   * Helper method to create an offline request object
   *
   * @param {*} type
   * @param {*} url
   * @param {*} value
   */
  createRequestObject (type, url, value) {
    const requestObj = {
      id: GUID.generateUUID(),
      type: type,
      url: url,
      value: value,
      dateCreated: new Date()
    }
    return requestObj
  },
  /**
   * Helper method to handle a failed request attempt
   *
   * @param {*} err
   * @param {*} appObj
   */
  handleFailedOnlineRequestAttempt (err, appObj) {
    // If its a network error it just means the server is not online yet
    if (err != null && err.message === 'Network Error') {
      this.isPostingData = false
    } else {
      // If not its probably an error when trying to synchronize
      let errorMessage = 'Error Synchronising Data: '
      if (err != null && err.response != null) {
        if (err.response.data != null && err.response.data.length > 0) {
          errorMessage += err.response.data[0].description
        }
      }
      appObj.$snotify.error(errorMessage)
    }
    this.isPostingData = false
  }
}

axios.interceptors.request.use((config) => {
  return Auth.currentSession()
    .then(function (data) {
      config.headers.Authorization = `Bearer ${data.idToken.jwtToken}`
      return Promise.resolve(config)
    })
    .catch(function (err) {
      console.log(err)
      return config
    })
}, (error) => {
  this.loading = false
  // Do something with request error
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response != null && error.response.status === 401 && error.response.headers['token-expired']) {
    store.commit('session/setIsLoggedIn', false)
    store.commit('session/setSessionUser', {})
    store.commit('session/setPermissions', { routes: {}, keys: {} })
    store.commit('session/setToken', null)
    store.commit('session/setTokenExpired', true)
    router.push('/login')
  }
  return Promise.reject(error)
})
