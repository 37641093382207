import Vue from 'vue'
import { Auth } from 'aws-amplify'
import { EventBus } from '../../components/events/eventBus'
const signalR = require('@microsoft/signalr')
Vue.prototype.$signalR = signalR

const namespaced = true

const state = {
  issueHub: null
}

// getters
const getters = {
  issueHub: (state) => {
    return state.issueHub
  }
}

// actions
const actions = {
  async connectIssueHub (context) {
    try {
      context.commit('setupIssueHub')
      const issueHub = context.getters.issueHub

      issueHub.on('MessageUpdate', data => {
        // Set the type to message so we know how to handle.
        data.notificationType = 'message'
        context.dispatch('notifications/newMessage', data, { root: true })

        EventBus.$emit('newMessage', data)
      })

      issueHub.on('IssueUpdate', data => {
        EventBus.$emit('issueUpdate', data)
      })

      await issueHub.start()
    } catch (err) {
      console.log(err)
    }
  }
}

// mutations
const mutations = {
  async setupIssueHub (state) {
    const scope = this
    state.issueHub = new scope._vm.$signalR.HubConnectionBuilder()
      .withUrl(process.env.SOCKETURL + 'websocket/issuehub', {
        accessTokenFactory: async () => {
          // Set up token.
          const data = await Auth.currentSession()
          return data.idToken.jwtToken
        }
      })
      .configureLogging(scope._vm.$signalR.LogLevel.Error)
      .build()
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
}
