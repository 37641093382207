<template>
  <v-footer app padless v-if="visible">
    <v-bottom-navigation color="primary" grow>
      <v-btn v-if="!$store.getters['session/getCognitoGroup']('user')" :to="'/developments'">
        <span>Developments</span>

        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn v-if="$store.getters['session/getCognitoGroup']('user')" :to="'/issues'">
        <span>All Issues</span>

        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>

      <v-btn :to="'/profile'">
        <span>Profile</span>

        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data: () => ({}),
  computed: {
    visible () {
      if (this.$route.meta.headerVisible) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
