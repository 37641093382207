/* eslint-disable no-undef */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { Auth } from 'aws-amplify'

Vue.use(VueRouter)

// We import the components this way to lazy load them.
const NotFound = () => import('../components/notFound.vue')
const Login = () => import('../components/login/login.vue')
const ForgottenPassword = () => import('../components/forgottenPassword/forgottenPassword.vue')
const Developments = () => import('../components/developments/developments.vue')
const IssuesList = () => import('../components/issues/issuesList.vue')
const Issue = () => import('../components/issues/issue.vue')
const Profile = () => import('../components/profile/profile.vue')
const CreateIssue = () => import('../components/issues/multiCreate.vue')

// The routes for the project.
const routes = [
  { path: '*', component: NotFound },
  { path: '/notFound', name: 'notFound', component: NotFound },
  {
    path: '/',
    component: Login,
    meta: { notAllowedAuthed: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { notAllowedAuthed: true }
  },
  {
    path: '/forgottenPassword',
    name: 'forgottenPassword',
    component: ForgottenPassword,
    meta: { notAllowedAuthed: true }
  },
  {
    path: '/createIssue',
    name: 'createIssue',
    component: CreateIssue,
    meta: { requiresAuth: true, requiresPermission: true, headerVisible: false, footerVisible: false }
  },
  {
    path: '/issues',
    name: 'allIssues',
    component: IssuesList,
    meta: { requiresAuth: true, requiresPermission: true, headerVisible: true }
  },
  {
    path: '/issues/:id',
    name: 'issue',
    component: Issue,
    meta: { requiresAuth: true, requiresPermission: true, headerVisible: false, footerVisible: false }
  },
  {
    path: '/developments',
    name: 'developments',
    component: Developments,
    meta: { requiresAuth: true, requiresPermission: true, headerVisible: true }
  },
  {
    path: '/developments/:id/issues',
    name: 'issuesList',
    component: IssuesList,
    meta: { requiresAuth: true, requiresPermission: true, headerVisible: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { requiresAuth: true, requiresPermission: true, headerVisible: true }
  }
]

const routeCheck = function (to, from, next) {
  if (to.meta.requiresAuth) {
    Auth.currentSession()
      .then(function () {
        next()
      })
      .catch(function (err) {
        console.log(err)
        routeBack(to, from)
      })
  } else if (to.meta.notAllowedAuthed) {
    Auth.currentSession()
      .then(function () {
        if (router.app.$store.getters['session/getCognitoGroup']('fixer')) {
          router.app.$router.push('/developments')
        } else {
          router.app.$router.push('/issues')
        }
      })
      .catch(function (err) {
        console.log(err)
        next()
      })
  } else {
    next()
  }
}

const routeBack = function (to, from) {
  if (from.matched.length === 0) {
    router.app.$router.push({ name: 'login', query: { redirect: to.fullPath } })
    router.app.$store.commit('session/setIsLoggedIn', false)
    router.app.$store.commit('session/setSessionUser', {})
    router.app.$store.commit('session/setPermissions', { routes: {}, keys: {} })
    router.app.$store.commit('session/setToken', null)
  } else {
    router.app.$router.push({ name: 'notFound' })
  }
}
const router = new VueRouter({ routes, mode: 'history' })

router.beforeEach((to, from, next) => {
  routeCheck(to, from, next)
})

export default router
